import moment from 'moment';
import { documentUnitOptions } from '../../utils/helpers/document.helpers';
import {
  DOCUMENT_STATUS,
  DOCUMENT_TYPE,
} from '../../utils/constants/document.constants';
import i18n from '../../i18n';

function getNNumbers(num, n = 2) {
  if (num.toString().length < n) {
    return getNNumbers(`0${num}`, n);
  }
  return num.toString();
}

class DocumentBusinessService {
  /**
   * Get format number document
   * For Onboarding
   *
   * @param {String} prefix
   * @param {String} dateFormat
   * @param {String} startAt
   * @returns {String}
   */
  getExampleDocumentFormatNumber(prefix, dateFormat, startAt) {
    const dateF = dateFormat.replace(/d/g, 'D').replace(/y/g, 'Y');
    const date = moment().format(dateF);
    const start = getNNumbers(startAt, 3);
    return `${prefix}-${date}-${start}`;
  }

  /**
   * Get total HT
   *
   * @param {Object} document
   * @returns {Number}
   */
  getTotalWithoutTaxDiscount(document) {
    const { items } = document;
    const total = items
      .map(it => it.price * it.quantity)
      .reduce((mem, current) => mem + current, 0);
    return parseFloat(total.toFixed(2));
  }

  /**
   * Get total disbursement items
   *
   * @param {Object} document
   * @returns {Number}
   */
  getTotalDisbursementItems(items) {
    if (items && items.length > 0) {
      const total = items
        .map(it => it.price * it.quantity)
        .reduce((mem, current) => mem + current, 0);
      return parseFloat(total.toFixed(2));
    }
    return 0;
  }

  /**
   * Get total HT with discount
   *
   * @param {Object} document
   * @returns {Number}
   */
  getTotalWithoutTax(document) {
    const { items } = document;
    const total = items
      .map(it => it.price * it.quantity)
      .reduce((mem, current) => mem + current, 0);
    return parseFloat((total * ((100 - document.discount) / 100)).toFixed(2));
  }

  /**
   * Get discount amount
   *
   * @param {Object} document
   * @returns {Number}
   */
  getDiscount(document) {
    return (
      this.getTotalWithoutTaxDiscount(document) -
      this.getTotalWithoutTax(document)
    );
  }

  /**
   * Get VAT amount
   *
   * @param {Object} document
   * @returns {Number}
   */
  getTax(document) {
    if (document.payment && document.payment.total) {
      return document.payment.total * (document.VAT / 100);
    } else {
      return this.getTotalWithoutTax(document) * (document.VAT / 100);
    }
  }

  /**
   * Get Total with tax
   *
   * @param {Object} document
   * @returns {Number}
   */
  getTotalWithTax(document) {
    if (document.payment && document.payment.total) {
      return document.payment.total + this.getTax(document);
    } else {
      return this.getTotalWithoutTax(document) + this.getTax(document);
    }
  }

  /**
   * Get Invoice payment total from downPayment percentage
   *
   * @param {Object} document
   * @param {Number} downPaymentPercent
   *
   * @returns {Number}
   */
  getTotalDownPayment(document, downPaymentPercent) {
    return this.getTotalWithoutTax(document) * (downPaymentPercent / 100);
  }

  /**
   * Get Invoice net commercial
   *
   * @param {Object} document
   * @param {Array} invoicesHistory
   * @param {String} currentInvoiceId invoice update
   *
   * @returns {Number}
   */
  getNetCommercial(document, invoicesHistory, currentInvoiceId) {
    // currentInvoiceId -> if we edit an invoice. document should be the estimate to get the right amount
    const estimateTotal = this.getTotalWithoutTax(document);
    const totalPayedInvoices = invoicesHistory
      .filter(
        invoice =>
          invoice._id !== document._id &&
          invoice._id !== currentInvoiceId &&
          !this.isCanceled(invoice) &&
          !this.isCredit(invoice)
      )
      .map(invoice =>
        invoice.payment
          ? invoice.payment.total
          : this.getTotalWithoutTax(invoice)
      )
      .reduce((mem, current) => mem + current, 0);

    return estimateTotal - totalPayedInvoices;
  }

  /**
   * Get Remaining to invoice
   *
   * @param {Object} estimate
   * @param {Array} invoicesHistory
   *
   * @returns {Number}
   */
  getRemainingToInvoice(estimate, invoicesHistory, currentInvoiceId) {
    // currentInvoiceId -> if we edit an invoice. document should be the estimate to get the right amount
    const estimateTotal = this.getTotalWithoutTax(estimate);

    const totalInvoiced = invoicesHistory
      .filter(
        invoice =>
          invoice._id !== currentInvoiceId &&
          !this.isCanceled(invoice) &&
          !this.isCredit(invoice)
      )
      .map(invoice => invoice.payment.total)
      .reduce((mem, current) => mem + current, 0);

    const invoiced = ((totalInvoiced / estimateTotal) * 100).toFixed(2);

    return 100 - invoiced;
  }

  /**
   * Is document draft
   *
   * @param {Object} document
   * @returns {Boolean}
   */
  isDraft({ status }) {
    return status === DOCUMENT_STATUS.DRAFT;
  }

  /**
   * Is document paid
   *
   * @param {Object} document
   * @returns {Boolean}
   */
  isPaid({ status }) {
    return status === DOCUMENT_STATUS.PAID;
  }

  /**
   * Is document sent
   *
   * @param {Object} document
   * @returns {Boolean}
   */
  isSent({ status }) {
    return status === DOCUMENT_STATUS.SENT;
  }

  /**
   * Is document canceled
   *
   * @param {Object} document
   * @returns {Boolean}
   */
  isCanceled({ status }) {
    return status === DOCUMENT_STATUS.CANCELED;
  }

  /**
   * Is document accepted
   *
   * @param {Object} document
   * @returns {Boolean}
   */
  isAccepted({ status }) {
    return status === DOCUMENT_STATUS.ACCEPTED;
  }

  /**
   * Is document declined
   *
   * @param {Object} document
   * @returns {Boolean}
   */
  isDeclined({ status }) {
    return status === DOCUMENT_STATUS.DECLINED;
  }

  /**
   * Is document declined
   *
   * @param {Object} document
   * @returns {Boolean}
   */
  isInvoiced({ status }) {
    return status === DOCUMENT_STATUS.INVOICED;
  }

  /**
   * Is document late
   *
   * @param {Object} document
   * @returns {Boolean}
   */
  isLate(document) {
    if (!this.isSent(document)) {
      return false;
    }
    if (document.paidAt) {
      return false;
    } else {
      return moment(document.dueAt).isBefore(moment(), 'day');
    }
  }

  /**
   * Is document external
   *
   * @param {Object} document
   * @returns {Boolean}
   */
  isExternal(document) {
    return !!document.external;
  }

  /**
   * Is credit document
   *
   * @param {Object} document
   * @returns {Boolean}
   */
  isCredit(document) {
    return !!document.creditInvoice;
  }

  /**
   * Get document status key
   *
   * @param {Object} document
   * @param {String} type
   * @returns {String}
   */
  getStatusKey(document, type = DOCUMENT_TYPE.INVOICE) {
    const { status, external } = document;
    if (external) {
      return 'document:status.ext';
    }
    if (type === DOCUMENT_TYPE.INVOICE && this.isCredit(document)) {
      return 'document:status.credit';
    }
    if (this.isLate(document)) {
      return 'document:status.late';
    }
    if (this.isSent(document)) {
      return type === DOCUMENT_TYPE.INVOICE
        ? 'document:status.sent_female'
        : 'document:status.sent_male';
    }
    if (this.isCanceled(document)) {
      return type === DOCUMENT_TYPE.INVOICE
        ? 'document:status.canceled_female'
        : 'document:status.canceled_male';
    }
    return `document:status.${status}`;
  }

  /**
   * Get document status color
   *
   * @param {Object} document
   * @returns {String}
   */
  getStatusToColor(document) {
    const { status, external } = document;

    if (external) {
      if (this.isSent({ status })) {
        return 'blue';
      } else {
        return 'yellow';
      }
    }
    if (this.isCredit(document)) {
      return 'pink';
    }
    if (this.isLate(document)) {
      return 'red';
    }
    if (this.isDraft({ status })) {
      return 'darkBlue';
    } else if (this.isSent({ status })) {
      return 'blue';
    } else if (this.isDeclined({ status })) {
      return 'red';
    } else if (this.isPaid({ status }) || this.isAccepted({ status })) {
      return 'green';
    } else if (this.isInvoiced({ status })) {
      return 'primary';
    }
  }

  /**
   * Get document Company Initial
   *
   * @param {Object} document
   * @returns {String}
   */
  getCompanyInital(document) {
    const { companyInformation } = document;
    return `${companyInformation.firstName[0]}${
      companyInformation.lastName[0]
    }`;
  }

  /**
   * Get document Company Name
   *
   * @param {Object} document
   * @returns {String}
   */
  getCompanyName(document) {
    const { companyInformation } = document;
    return `${companyInformation.firstName} ${companyInformation.lastName}`;
  }

  /**
   * Get document Company City + ZipCode
   *
   * @param {Object} document
   * @returns {String}
   */
  getCompanyCity(document) {
    const { companyInformation } = document;
    return `${companyInformation.zipCode} ${companyInformation.city}`;
  }

  /**
   * [INVOICE]
   * Get dueAt number of days
   *
   * @param {Object} invoice
   * @returns {Number}
   */
  getDueAtTiming(invoice) {
    return moment(invoice.dueAt).diff(moment(invoice.createdAt), 'days');
  }

  /**
   * [ESTIMATE]
   * Get validity number of days
   *
   * @param {Object} estimate
   * @returns {Number}
   */
  getValidityTiming(estimate) {
    return moment(estimate.validity).diff(moment(estimate.createdAt), 'days');
  }

  /**
   * [INVOICE]
   * Get moment diff date from invoice dueAt
   *
   * @param {Object} invoice
   * @returns {Number}
   */
  getDiff(invoice) {
    return moment(invoice.dueAt).diff(moment(), 'days');
  }

  /**
   * [INVOICE]
   * Get invoice dueAt in or Paid at
   *
   * @param {Object} invoice
   * @returns {String}
   */
  getDueDate(invoice) {
    if (this.isDraft(invoice)) {
      return '-';
    } else if (this.isPaid(invoice) || invoice.paidAt) {
      return moment(invoice.paidAt).format('L');
    } else {
      const diff = this.getDiff(invoice);
      if (diff > 0) {
        return `Due dans ${diff} j.`;
      } else if (diff === 0) {
        return 'Due auj.';
      } else {
        return `Due il y a ${-1 * diff} j.`;
      }
    }
  }

  /**
   * Get formated document sent at date
   *
   * @param {Object} document
   * @returns {String}
   */
  getSentAt(document) {
    return moment(document.sentAt).format('L');
  }

  /**
   * [INVOICE]
   * Get formatted invoice paid at date
   *
   * @param {Object} invoice
   * @returns {String}
   */
  getPaidAt(invoice) {
    return moment(invoice.paidAt).format('L');
  }

  /**
   * [ESTIMATE]
   * Get formatted estimate accepted at date
   *
   * @param {Object} estimate
   * @returns {String}
   */
  getAcceptedAt(estimate) {
    return moment(estimate.acceptedAt).format('L');
  }

  /**
   * [ESTIMATE]
   * Get formated estimate declined at date
   *
   * @param {Object} estimate
   * @returns {String}
   */
  getDeclindedAt(estimate) {
    return moment(estimate.declinedAt).format('L');
  }

  /**
   * Get formatted document canceled at
   *
   * @param {Object} document
   * @returns {String}
   */
  getCanceledAt(document) {
    return moment(document.canceledAt).format('L');
  }

  /**
   * Get document title
   *
   * @param {Object} document
   * @returns {String}
   */
  getTitle(document) {
    return document.userTitle ? document.userTitle : document.title;
  }

  /**
   * Get document item unit label
   *
   * @param {Object} item
   * @returns {String | Null}
   */
  getItemUnitLabel(item) {
    const unit = documentUnitOptions.find(el => el.value === item.unit);
    if (unit) {
      return unit.label;
    } else {
      return null;
    }
  }

  /**
   * Get document item quantity with unit
   *
   * @param {Object} item
   * @returns {String | Number}
   */
  getItemQtyWithLabel(item) {
    // TODO: REFACTO WITH TRANSLATION KEY
    if (!!item.unit) {
      const unit = this.getItemUnitLabel(item);
      if (unit) {
        return `${item.quantity ? item.quantity : ''} ${unit}${
          item.quantity > 1 ? 's' : ''
        }`;
      }
    }
    return item.quantity;
  }

  /**
   * Get document title for pdf file
   *
   * @param {Object} document
   * @param {String} documentType
   * @param {Boolean} withExtension
   * @returns {String}
   */
  getTitleForPdf(document, documentType, withExtension = false) {
    const docType =
      documentType === DOCUMENT_TYPE.ESTIMATE ? 'Devis' : 'Facture';
    if (this.isDraft(document)) {
      return document.userTitle
        ? `${docType}_${document.userTitle}${withExtension ? '.pdf' : ''}`
        : `${docType}_brouillon${withExtension ? '.pdf' : ''}`;
    } else {
      return document.userTitle
        ? `${docType}_${document.userTitle}${withExtension ? '.pdf' : ''}`
        : `${docType}_${document.title}${withExtension ? '.pdf' : ''}`;
    }
  }

  /**
   * Get document title without prefix
   *
   * @param {Object} document
   * @returns {String}
   */
  getTitleWithoutPrefix(document) {
    if (document.userTitle) {
      return document.userTitle;
    }
    const [prefix, date, number] = document.title.split('-');
    return [date, number].join('-');
  }

  /**
   * [INVOICE]
   * Get document title for creation
   *
   * @param {Object} userSettings
   * @param {Number} createdAt
   * @param {Number} number
   * @returns {String}
   */
  getInvoiceTitleForCreation(userSettings, createdAt, number) {
    if (number === 1) {
      //First document
      number = parseInt(userSettings.invoices.startAt);
    }
    const prefix = userSettings.invoices.prefix;
    const format = userSettings.invoices.dateFormat.toUpperCase();
    const date = moment(createdAt).format(format);
    const numberFormated = getNNumbers(number, 3);
    return `${prefix}-${date}-${numberFormated}`;
  }

  /**
   * [ESTIMATE]
   * Get document title for creation
   *
   * @param {Object} userSettings
   * @param {Number} createdAt
   * @param {Number} number
   * @returns {String}
   */
  getEstimateTitleForCreation(userSettings, createdAt, number) {
    if (number === 1) {
      //First document
      number = parseInt(userSettings.estimates.startAt);
    }
    const prefix = userSettings.estimates.prefix;
    const format = userSettings.estimates.dateFormat.toUpperCase();
    const date = moment(createdAt).format(format);
    const numberFormated = getNNumbers(number, 3);
    return `${prefix}-${date}-${numberFormated}`;
  }

  /**
   * Display EI mention or not
   *
   * @param {Object} user
   * @param {String} date
   * @returns {Boolean}
   */
  displayEIMention(user, date) {
    // 15 MAI 2022
    const fromDate = 1652572800000;
    const createDate = typeof date !== 'number' ? date.valueOf() : date;
    return user.settings.company.status === 'micro' && createDate > fromDate;
  }

  /**
   * Get company information to string for display only in document
   *
   * @param {Object} document
   * @param {Object} user
   * @param {String} locale
   * @returns {String}
   */
  generateCompanyInformation(document, user, locale = 'fr') {
    const {
      firstName,
      lastName,
      email,
      address,
      zipCode,
      city,
      identificationNumber,
      VATNumber,
    } = document.companyInformation;

    const name = `${firstName} ${lastName}`;
    const legalMention = this.displayEIMention(user, document.createdAt)
      ? ' Entrepreneur individuel'
      : '';
    const fullAddress = `${address}, ${zipCode} ${city}`;
    const inRegistrationProcess = i18n.t('document:registration_process', {
      lng: locale,
    });
    const siret = i18n.t('document:siret_with_value', {
      lng: locale,
      siret:
        identificationNumber !== '-waiting-'
          ? identificationNumber
          : inRegistrationProcess,
    });
    const VAT = !!VATNumber
      ? `| ${i18n.t('document:full_vat_number_with_value', {
          lng: locale,
          vat_number: VATNumber,
        })}`
      : '';

    return `${name}${legalMention} | ${fullAddress} | ${siret} ${VAT} | ${email}`;
  }

  getCleanedLegalNotice(document) {
    return document.legalNotice
      .replace(/<<JSONDATA>>.*?<<ENDJSON>>/g, '')
      .trim();
  }

  getDisbursementItems(document) {
    if (document?.legalNotice) {
      const regex = /<<JSONDATA>>(.*?)<<ENDJSON>>/;
      const match = document.legalNotice.match(regex);

      if (match) {
        const extractedJson = match[1];
        const parsedData = JSON.parse(extractedJson);
        return parsedData;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * Get formated external invoice from data
   *
   * @param {Object} invoice
   * @returns {Object}
   */
  createExternalInvoiceFrom({
    name,
    projectId,
    subject,
    amountWithoutTax,
    VAT,
    sentAt,
    paidAt,
    paidWith,
  }) {
    return {
      projectId,
      external: true,
      userTitle: name,
      subject,
      sentAt,
      paidAt,
      paidWith,
      VAT,
      items: [
        {
          title: subject,
          description: '',
          quantity: 1,
          price: amountWithoutTax,
        },
      ],
    };
  }
}

export const documentBusinessService = new DocumentBusinessService();
