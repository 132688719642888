import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { tint } from 'polished';

import TextareaAutosize from 'react-textarea-autosize';
import { Text } from '../typography';
import { Tooltip } from '../tooltip';
import Icons from '../icons';

// Styled component
const InputGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  color: ${props => props.theme.colors.darkBlue};
`;

// Input
const InputElement = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: ${({ large }) => (large ? '4.8rem' : '4rem')};
  min-height: 4rem;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  text-indent: ${({ icon, iconRight, type }) =>
    type === 'textarea' ? '0' : icon && !iconRight ? '4rem' : '1.5rem'};
  padding-left: ${({ type }) => (type === 'textarea' ? '1.5rem' : '0')};
  padding-right: ${props =>
    props.icon && props.iconRight ? '4rem' : '1.5rem'};
  font-size: ${({ large }) => (large ? '1.6rem' : '1.3rem')};
  line-height: ${({ large }) => (large ? '2.4rem' : '1.6rem')};
  color: ${props => props.theme.colors.darkBlue};
  border: 1px solid
    ${props =>
      !props.error
        ? tint(1 - 0.15, props.theme.colors.darkBlue)
        : props.theme.colors.red};
  border-radius: ${props => props.theme.radius.regular};
  background-color: ${props => props.theme.colors.white};
  box-sizing: border-box;
  transition: all 0.25s ease-in-out;

  &[type='number'] {
    -moz-appearance: textfield;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    font-family: 'Inter', sans-serif;
    -webkit-box-shadow: 0 0 0px 1000px
      ${props => tint(1 - 0.05, props.theme.colors.primary)} inset;
    background-color: ${props => tint(1 - 0.9, props.theme.colors.darkBlue)};
  }

  ::placeholder {
    color: ${props => tint(1 - 0.25, props.theme.colors.darkBlue)};
    font-style: italic;
  }

  &:focus {
    outline: none;
    border-color: ${props =>
      !props.error ? props.theme.colors.primary : props.theme.colors.red};
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const TextAreaElement = styled(TextareaAutosize)`
  -webkit-appearance: none;
  resize: ${({ resize }) => (resize ? resize : 'none')};
  width: 100%;
  min-height: 4rem;
  padding: 1.1rem 1.5rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: ${props => props.theme.colors.darkBlue};
  border: 1px solid
    ${props =>
      !props.error
        ? tint(1 - 0.15, props.theme.colors.darkBlue)
        : props.theme.colors.red};
  border-radius: ${props => props.theme.radius.regular};
  background-color: ${props => props.theme.colors.white};
  box-sizing: border-box;
  transition: border 0.25s ease-in-out, opacity 0.25s ease-in-out;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  ::placeholder {
    color: ${props => tint(1 - 0.25, props.theme.colors.darkBlue)};
    font-style: italic;
  }

  &:focus {
    outline: none;
    border-color: ${props =>
      !props.error ? props.theme.colors.primary : props.theme.colors.red};
  }

  &:disabled {
    opacity: 0.5;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
`;

// Icon
const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props =>
    props.iconRight
      ? css`
          right: 1.6rem;
        `
      : css`
          left: 1.6rem;
        `}

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

const InputLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
`;

// Label
const Label = styled(Text)`
  flex-shrink: 0;
  flex-grow: 1;
  transition: all 0.25s ease-in-out;
`;

// Error
const Error = styled(Text)`
  margin-top: 0.4rem;
`;

// Component
const Input = props => {
  const { iconRight, large, iconTooltip, maxLength, ...otherProps } = props;
  const [inputFocus, setInputFocus] = useState(false);

  return (
    <InputGroup>
      {props.error && (
        <Error type='span' color='red' tint={1}>
          {props.error}
        </Error>
      )}
      <InputContainer>
        {props.type === 'textarea' ? (
          <TextAreaElement
            id={props.name}
            minRows={5}
            maxLength={maxLength}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            {...otherProps}
          />
        ) : (
          <InputElement
            id={props.name}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            {...props}
            as={props.as}
          />
        )}
        {props.icon &&
          (props.iconTooltip ? (
            <InputIcon iconRight={props.iconRight} onClick={props.iconAction}>
              <Tooltip content={props.iconTooltip}>
                {Icons[props.icon]({})}
              </Tooltip>
            </InputIcon>
          ) : (
            <InputIcon iconRight={props.iconRight} onClick={props.iconAction}>
              {Icons[props.icon]({})}
            </InputIcon>
          ))}
      </InputContainer>
      {(props.label || props.link) && (
        <InputLabel>
          <Label
            htmlFor={props.name}
            type='label'
            size='medium'
            weight='medium'
            tint={inputFocus ? 1 : props.disabled ? 0.25 : 0.75}
            color={inputFocus ? 'primary' : ''}
          >
            {props.label} {props.required ? '*' : null}
          </Label>
          {props.link}
        </InputLabel>
      )}
    </InputGroup>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  iconRight: PropTypes.bool,
  iconAction: PropTypes.func,
  iconTooltip: PropTypes.string,
  onChange: PropTypes.func,
  link: PropTypes.element,
  resize: PropTypes.string,
  large: PropTypes.bool,
  maxLength: PropTypes.number,
};

Input.defaultProps = {
  label: null,
  required: false,
  disabled: false,
  type: 'text',
  name: null,
  placeholder: null,
  value: null,
  icon: null,
  iconRight: false,
  iconTooltip: null,
  link: null,
  resize: null,
  large: false,
};

export default Input;
